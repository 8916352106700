<template>
	<!-- 我的申请 -->
	<div style="background: #eee">
		<van-sticky>
			<van-nav-bar
				title="入职申请"
				left-text="返回"
				right-text="新增"
				left-arrow
				@click-left="goBack"
				@click-right="showAddFun"
			/>
			<van-search
				v-model="params.content"
				show-action
				placeholder="姓名/电话号码/身份证号"
				@search="onSearch"
			>
				<template #action>
					<div @click="onSearch()" style="color: red">查询</div>
				</template>
			</van-search>
		</van-sticky>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-cell-group
				v-for="(item, index) in userEntryList"
				:key="index"
				style="margin: 1.2rem; text-align: left"
				inset
			>
				<div class="circle-ready" v-show="item.approveStatus === 0">待审核</div>
				<div class="circle-Pass" v-show="item.approveStatus === 1">审核通过</div>
				<div class="circle-noPass" v-show="item.approveStatus === 2">审核驳回</div>
				<van-cell title="名称" :value="item.name" />
				<van-cell title="身份证号" :value="item.idCard" />
				<van-cell title="电话号码" :value="item.telephone" />
				<van-cell title="文化程度" :value="item.education" />
				<van-cell title="职位" :value="item.job" />
				<van-cell title="身份证照片" colon>
					<van-image :src="getPreviewImage(item.idPhoto)"></van-image>
					<van-image-preview
						:src="getPreviewImage(item.idPhoto)"
						v-show="showPreViewImage"
					></van-image-preview>
				</van-cell>
				<van-field>
					<template #button>
						<van-button
							type="primary"
							size="small"
							style="margin-left: 1rem"
							v-show="item.approveStatus === 0"
							@click="approveFun(item.id, 1)"
							>审核通过
						</van-button>
						<van-button
							type="danger"
							size="small"
							style="margin-left: 1rem"
							v-show="item.approveStatus === 0"
							@click="approveFun(item.id, 2)"
							>审核驳回
						</van-button>
					</template>
				</van-field>
			</van-cell-group>
		</van-list>
	</div>
</template>

<script>
	import { Toast } from 'vant';

	export default {
		data() {
			return {
				searchUserEntry: '',
				loading: false,
				finished: false,
				flagUpload: true,
				userEntryList: [],
				params: {
					size: 5,
					page: 1,
					content: '',
				},
				approveParam: {
					Id: 0,
					approveStatus: 0,
				},
				userEntryImage: [],
				showPreViewImage: false,
				defaultUrl: '',
			};
		},
		created() {
			this.userEntryImage = [];
			this.params.page = 1;
			this.queryUserEntryList();
		},
		methods: {
			onLoad() {
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				setTimeout(() => {
					this.params.page = this.params.page + 1;
					this.queryUserEntryList();
				}, 2000);
			},
			goBack() {
				this.$router.push('/');
			},
			onSearch() {
				this.params.page = 1;
				this.queryUserEntryList();
			},
			showAddFun() {
				this.$router.push({ path: '/EntryAdd' });
			},
			queryUserEntryList() {
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/select-user-entry',
					data: this.params,
				}).then((res) => {
					if (this.params.page === 1) {
						this.userEntryList = [];
					}
					if (res == null || res.data == null || res.data.data == null) {
						this.finished = true;
						this.userEntryList = [];
					} else {
						for (let i = 0; i < res.data.data.list.length; i++) {
							this.userEntryList.push(res.data.data.list[i]);
						}
						if (res.data.data.list.length <= 0) {
							this.finished = true;
						}
					}
					// 加载状态结束
					this.loading = false;
				});
			},
			approveFun(id, status) {
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/audit-user-entry/' + id,
					data: { approveStatus: status },
				}).then((res) => {
					if (res.data.code === 200) {
						this.userEntryList = [];
						this.userEntryImage = [];
						this.params.page = 1;
						this.queryUserEntryList();
						Toast.success('审核成功');
					} else {
						Toast.fail(res.data.message);
					}
				});
			},
			getPreviewImage(imgUrl) {
				if (imgUrl === undefined || imgUrl === null || imgUrl === 'deleteImage') {
					return this.defaultUrl;
				}
				var imageIndex = this.userEntryImage.findIndex((item) => {
					return item.oriUrl == imgUrl;
				});
				if (imageIndex !== -1) {
					return this.userEntryImage[imageIndex].url;
				}
				let imageObj = {};
				imageObj.oriUrl = imgUrl;
				imageObj.url = '/api/v1/web/order-info/preview-image?path=' + imgUrl;
				this.userEntryImage.push(imageObj);
				return imageObj.url;
			},
		},
	};
</script>

<style>
	.circle-backboard {
		width: 0px;
		height: 0px;
		border-top: none;
		border-right: 4rem solid rgb(25, 137, 250);
		border-bottom: 4rem solid transparent;
		border-left: 4rem solid transparent;
		border-image: initial;
		float: right;
		margin-top: -3rem;
		margin-right: 0.8rem;
		position: relative;
		margin-bottom: 0.4rem;
	}
	.circle-ready {
		text-align: right;
		margin: 0.5rem 1rem;
		color: #ffdd00;
	}
	.circle-Pass {
		text-align: right;
		margin: 0.5rem 1rem;
		color: green;
	}
	.circle-noPass {
		text-align: right;
		margin: 0.5rem 1rem;
		color: red;
	}
</style>
